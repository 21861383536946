<template>
  <div class="about-view3">
    <img src="../../../assets/images/About/aboutperson.png" width="100%">
<!--    <div class="about-view3-main">-->
<!--      <div class="about-person">-->
<!--        <div class="person-mask flex flex-column">-->
<!--          <div class="item-title" style="flex: 1">-->
<!--            <kcTitle style="color: #FFFFFF"  title="企业文化" en="Corporate Culture"></kcTitle>-->
<!--&lt;!&ndash;            <div class="mask-title1">企业文化       <span class="font-size-14 pb5">■</span>&ndash;&gt;-->
<!--&lt;!&ndash;            </div>&ndash;&gt;-->
<!--&lt;!&ndash;            <div class="mask-title2 mt10">Corporate Culture</div>&ndash;&gt;-->
<!--          </div>-->
<!--          <div class="item-title" style="flex: 1">-->
<!--            <div class="mask-title1">公司愿景</div>-->
<!--            <div class="mask-title2 mt10">更健康的生活，更幸福的家庭</div>-->
<!--          </div>-->
<!--          <div class="item-title" style="flex: 1">-->
<!--            <div class="mask-title1">公司使命</div>-->
<!--            <div class="mask-title2 mt10">给农业插上科技的翅膀</div>-->
<!--          </div>-->
<!--          <div class="item-title" style="flex: 1">-->
<!--            <div class="mask-title1">公司价值观</div>-->
<!--            <div class="mask-title2 mt10">以奋斗者为本，持续创新，持续为 客户创造价值</div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<script>
export default {
  name: "view3",
  data() {
    return {
      show: false,
    }
  }
}
</script>

<style lang="scss" scoped>
.about-view3 {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;

  .about-view3-main {
    position: absolute;
    min-width: 1200px;
    width: 1440px;
    height: 100%;

    .about-person {
      height: 100%;

      .person-mask {
        padding: 50px;
        width: 20%;
        height: calc(100% - 100px);
        background: rgba(0, 0, 0, 0.45);

        .item-title {
          flex: 1;
          &:hover >.mask-title1{
            font-size: 40px;
          }

          .mask-title1 {
            transition: all 0.3s;
            font-size: 30px;
            color: #FFFFFF;
          }

          .mask-title2 {
            font-size: 16px;
            color: #FFFFFF;
          }
        }

      }
    }
  }
}
</style>
