<template>
  <div class="app-container about-main">
    <div style="width: 100%">
      <img src="../../assets/images/About/u2369_div.png" width="100%">
    </div>
    <div class="mt20" id="view1">
      <view1/>
    </div>
    <div class="mt20" id="view2">
      <view2/>
    </div>
    <div id="view3">
      <view3/>
    </div>
    <div id="view4">
      <view4/>
    </div>
    <div>
      <bottom/>
    </div>
  </div>
</template>

<script>
import view1 from "@/views/About/components/view1";
import view2 from "@/views/About/components/view2";
import view3 from "@/views/About/components/view3";
import view4 from "@/views/About/components/view4";
import bottom from "@/components/bottom";

export default {
  name: "index",
  components: {
    view1,
    view2,
    view3,
    view4,
    bottom
  },
  watch: {
    $route: {
      deep: true,
      handler() {
        // this.$nextTick(() => {
        //   let that = this;
        //   if (that.$route?.query?.id) {
        //     let id = that.$route.query.id;
        //     if (that.$el.querySelector(`#${id}`)) {
        //       document.body.scrollTop = that.$el.querySelector(`#${id}`).scrollIntoView();
        //     }
        //   }
        // });
      },
      immediate: true
    },
  }
}
</script>

<style scoped>
.about-main {
}
</style>
