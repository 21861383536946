<template>
  <div class="about-view4">
    <div class="about-view4-content">
      <kcTitle title="企业荣誉" en="HONORS"></kcTitle>
      <div class="about-view4-main flex justify-content-between">
        <div class="about-view4-left flex align-items-center" @click="prev"><img
            src="../../../assets/images/About/img/icon.png">
        </div>
        <div class="about-view4-cont flex justify-content-between">
          <div class="about-view4-img flex justify-content-between"
               :style="{transform: `translateX(${(showNum)*-265}px)`}">
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img3.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img14.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img1.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img2.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img9.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img4.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img5.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img6.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img7.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img8.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img10.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img11.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img12.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img13.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img3.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img14.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img1.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img2.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img9.jpg"
                                                               width="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img4.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img5.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img6.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img7.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img8.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img10.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img11.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img12.jpg"
                                                               height="100%"></div>
            <div class="cont-img flex align-items-center"><img src="../../../assets/images/About/img/img13.jpg"
                                                               height="100%"></div>

          </div>
        </div>
        <div class="about-view4-right flex align-items-center justify-content-end" @click="next">
          <img src="../../../assets/images/About/img/icon.png"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view4",
  data() {
    return {
      showNum: 0
    }
  },
  mounted() {
    setInterval(() => {
      if (this.showNum < 14) {
        this.next()
      }else {
        let dom = document.getElementsByClassName('about-view4-img')[0]
        dom.style.transition = 'none'
        this.showNum = 0
        window.setTimeout(() => {
          dom.style.transition = 'all 0.5s'
        }, 100)
      }
    }, 2000)
  },
  methods: {
    prev() {
      if (this.showNum != 0) {
        this.showNum--
      }
    },
    next() {
      if (this.showNum != 14) {
        this.showNum++
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.about-view4 {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 60px;
  padding-bottom: 60px;
  background: #F5F5F5;

  .about-view4-content {
    min-width: 1200px;
    width: 1440px;

    .about-view4-main {
      margin-top: 50px;
      height: 260px;

      & > .about-view4-left {
        width: 50px;
        transform-origin: 0 50%;
        transform: rotateY(180deg);
      }

      & > .about-view4-right {
        width: 50px;
      }

      & > .about-view4-cont {
        width: calc(100% - 100px);
        //padding: 0 50px;
        overflow-y: hidden;
        position: relative;
        height: 100%;

        .about-view4-img {
          position: absolute;
          height: 100%;
          top: 0;
          transition: all 0.3s;

          & > .cont-img {
            width: 215px;
            margin-left: 25px;
            margin-right: 25px;
          }
        }

      }
    }
  }
}
</style>
