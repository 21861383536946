<template>
  <div class="about-view2-main flex align-items-center justify-content-end">
    <div class="about-view2-content">
      <kcTitle style="margin-top: 60px" title="发展历程" en="HISTORY"></kcTitle>
      <div class="about-view2-body flex align-items-center justify-content-between">
        <div>
          <div style="margin-top: 60px" class="about-view2-text1">
            迄今为止
          </div>
          <div style="margin-top: 12px" class="about-view2-text1">
            累计服务上万家养殖场
          </div>
          <div style="margin-top: 12px" class="about-view2-text1">
            目前链接智能终端已经超过二十万台
          </div>
          <div  style="width: 290px; margin-top: 50px">
            <img src="../../../assets/images/About/upLine.png" width="100%"/>
          </div>
        </div>
        <div>
          <div class="about-view2-img ">
            <div id="img" class="flex align-items-center">
              <div class="about-view2-img-item" v-for="(item, index) in dataList" :key="item.title">
                <img v-if="(index + 1) == pageNum" :src="item.img" :class="{'about-view2-img-item-box': (index + 1) == pageNum, 'about-view2-img-item-opacity': (index + 1) != pageNum}">
                <img v-else @click="next" :src="item.img" :class="{'about-view2-img-item-box': (index + 1) == pageNum, 'about-view2-img-item-opacity': (index + 1) != pageNum}">
                <div class="title flex align-items-center justify-content-center">{{ item.title }}</div>
              </div>
            </div>
          </div>
          <div class="flex justify-content-center footer">
            <div class="flex justify-content-start align-items-center">
              <img src="../../../assets/images/News/prev.png" @click="prev" width="50%"/>
            </div>
            <div class="flex justify-content-center align-items-end">
              <div class="out-pageNum">
                <div :style="{transform: `translateY(${(pageNum-1)*-20}px)`}" class="flex flex-column num-content">
                  <div v-for="item in 6" :key="item" class="pageNum">{{ getNum(item) }}</div>
                </div>
              </div>
              <div> /</div>
              <div class="totalPageNum">{{ getNum(6) }}</div>
            </div>
            <div class="flex justify-content-end align-items-center">
              <img src="../../../assets/images/News/next.png" @click="next" width="50%"/>
            </div>
          </div>
        </div>
        <div class="about-view2-timeline">
          <img src="../../../assets/images/About/timeLine.png" width="100%"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "view2",
  components: {
  },
  data() {
    return {
      show: false,
      active: 0,
      pageSize: 1,
      pageNum: 1,
      dataList: [
        {
          title: "总部",
          img: require('../../../assets/images/About/1.png')
        },
        {
          title: "深耕",
          img: require('../../../assets/images/About/2.png')
        },
        {
          title: "团队",
          img: require('../../../assets/images/About/3.png')
        },
        {
          title: "产品",
          img: require('../../../assets/images/About/4.png')
        },
        {
          title: "展会",
          img: require('../../../assets/images/About/5.png')
        },
        {
          title: "展厅",
          img: require('../../../assets/images/About/6.png')
        }
      ],
      Interval: null,
      Timeout: null
    }
  },
  created() {

  },
  mounted() {
    this.$nextTick(() => {
      let img = document.getElementById("img")
      if (!this.Interval) {
        this.Interval = setInterval(() => {
          if (this.pageNum != 6) {
            let num = this.pageNum * -700
            img.style.transform = `translateX(${num}px)`
            this.pageNum = parseInt(this.pageNum) + 1
          } else {
            img.style.transition = 'none'
            img.style.transform = `translateX(0px)`
            img.style.transition = 'all 0.5s'
            this.pageNum = 1
          }
        }, 2000)
      }
    })
  },
  methods: {
    getNum(num) {
      let value = 0
      if (num < 10) {
        value = "0" + num
        return value
      } else {
        value = num
        return value
      }
    },
    prev() {
      if (this.pageNum != 1) {
        let img = document.getElementById("img")
        let num = (this.pageNum - 2) * 700 * -1
        img.style.transform = `translateX(${num}px)`
        this.pageNum = parseInt(this.pageNum) - 1
      }
      if (this.Interval) {
        clearInterval(this.Interval)
        this.Interval = null
        this.start()
      }
    },
    next() {
      if (this.pageNum != 6) {
        let img = document.getElementById("img")
        let num = this.pageNum * -700
        img.style.transform = `translateX(${num}px)`
        this.pageNum = parseInt(this.pageNum) + 1
      }
      if (this.Interval) {
        clearInterval(this.Interval)
        this.Interval = null
        this.start()
      }
    },
    start() {
      if (this.Timeout) {
        clearTimeout(this.Timeout)
      }
      this.Timeout = window.setTimeout(() => {
        this.Interval = setInterval(() => {
          let img = document.getElementById("img")
          if (this.pageNum != 6) {
            let num = this.pageNum * -700
            img.style.transform = `translateX(${num}px)`
            this.pageNum = parseInt(this.pageNum) + 1
          } else {
            img.style.transition = 'none'
            img.style.transform = `translateX(0px)`
            img.style.transition = 'all 0.5s'
            this.pageNum = 1
          }
        }, 2000)
      },2000)
    }
  }
}
</script>

<style lang="scss" scoped>
.about-view2-main {
  background: #F5F5F5;
  padding-bottom: 60px;
  .about-view2-content {
    width: calc(100% - 240px);
    min-width: 1200px;
    .about-view2-text1 {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 23px;
    }
  }
}
.about-view2-body {
  position: relative;
  .about-view2-timeline {
    position: absolute;
    bottom: 40px;
    left: 380px;
    width: 326px;
    height: 363px;
    background: #4D4948;
    overflow: auto;

  }
  .about-view2-timeline::-webkit-scrollbar {
    /*滚动条整体样式*/
    width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
    height: 100px;
  }
  .about-view2-timeline::-webkit-scrollbar-thumb {
    /*滚动条里面小方块*/
    border-radius: 10px;
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
    background   : #535353;
  }
  .about-view2-timeline::-webkit-scrollbar-track {
    /*滚动条里面轨道*/
    box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    background   : #ededed;
  }
}
.about-view2-img {
  width: 950px;
  height: 400px;
  overflow: hidden;
}
.about-view2-img-item {
  width: 600px;
  position: relative;
  margin-right: 100px;
  .title {
    position: absolute;
    width: 130px;
    height: 50px;
    background: #18933B;
    right: -65px;
    top: 100px;
    font-size: 24px;
    font-weight: 500;
    color: #FFFFFF;
    line-height: 30px;
  }
  img {
    width: 600px;
  }
}
.footer {
  margin-top: 30px;

  .out-pageNum {
    height: 21px;
    width: 20px;
    overflow-y: hidden;


    .num-content {
      transition: all 0.7s;

      .pageNum {
        height: 20px;
        font-size: 16px;
        color: #333333;
      }
    }

  }

  .totalPageNum {
    font-size: 12px;
    color: #999999;
  }
}
#img {
  transition: all 0.5s;
}
.about-view2-img-item-box {
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  transform: scale(1.03);
  transition: all 0.5s;
}
.about-view2-img-item-opacity {
  opacity: 0.3;
  transition: all 0.5s;
}
</style>
